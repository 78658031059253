<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col class="text-h6 font-weight-regular pt-0 pb-4">
      Nueva venta
    </v-col>
    <!-- ---------------- -->
    <!-- LOCAL Y VENDEDOR -->
    <!-- ---------------- -->
    <v-col cols="12">
      <v-row>
        <v-col cols="6" sm="3" md="2" class="py-0">
          <v-autocomplete
            v-model="sucursal"
            ref="atcSucursal"
            label="Sucursal"
            item-text="nombre"
            item-value="id"
            tabindex="1"
            :items="sucursales"
            outlined
            dense
            @change="getPtovta()"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="py-0">
          <v-autocomplete
            v-model="ptovta"
            ref="atcLocal"
            label="Local"
            item-text="nombre"
            tabindex="1"
            :items="locales_user.filter(loc => loc.sucursal == sucursal && loc.canal == 1)"
            return-object
            outlined
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="9" md="6">
          <v-row>
            <v-col cols="4" class="py-0">
              <v-text-field
                v-model.trim="vendedor"
                label="Vendedor"
                type="number"
                tabindex="1"
                outlined
                dense
                @blur="buscarVendedor()"
                @keypress="enterKey"
              ></v-text-field>
            </v-col>
            <v-col cols="8" class="py-0">
              <v-text-field
                v-model.trim="vendedor_nombre"
                label="Nombre"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <!-- ----------------------- -->
    <!-- COMPONENTE DE ARTICULOS -->
    <!-- ----------------------- -->
    <template>
      <!-- CABECERA ART -->
      <v-col cols="12" class="pt-0">
        <v-divider></v-divider>
        <div class="d-flex align-center py-3" style="font-size: 18px;">
          <v-icon left>fas fa-shopping-cart</v-icon>
          Artículos
          <v-btn
            color="info"
            class="ml-2"
            title="Buscar artículos"
            small
            icon
            @click="modal_articulos = true"
          >
            <v-icon small>fas fa-search</v-icon>
          </v-btn>
        </div>
        <v-row>
          <v-col cols="12" sm="6" md="4" xl="3" class="d-flex align-center">
            <v-text-field
              v-model.trim="articulo"
              ref="vtfArticulo"
              label="Artículo"
              type="tel"
              class="mr-3"
              tabindex="1"
              hide-details
              outlined
              dense
              @blur="buscarArticulo(1)"
              @keypress="enterKey"
            ></v-text-field>
            <v-btn
              color="info"
              tabindex="1"
              @click="buscarArticulo(1)"
            >
              Agregar
            </v-btn>
          </v-col>
          <v-col cols="4" sm="3" md="2" class="d-flex justify-center">
            <v-switch
              v-model="combo"
              label="Combo"
              class="mt-0 mr-1"
              tabindex="1"
              hide-details
            ></v-switch>
          </v-col>
          <v-col cols="8" sm="6" md="4" xl="3">
            <v-text-field
              v-model.trim="cupon"
              label="Cupón de descuento"
              tabindex="1"
              hide-details
              clearable
              outlined
              dense
              @change="validarCupon()"
            ></v-text-field>
          </v-col>
          <v-col cols="8" sm="6" md="2">
            <v-btn
              tabindex="1"
              color="primary"
              :disabled="articulos.filter(art => art.gift_card == 1).length > 0 || giftcard"
              @click="openGF({})"
            >
              <v-icon left>fas fa-gift</v-icon>
              Gift Card
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <!-- LISTA DE ARTICULOS -->
      <v-col cols="12">
        <v-data-table
          class="elevation-2 cebra"
          item-key="codigo"
          :items="articulos"
          :headers="headersArt"
          :items-per-page="-1"
          :item-class="itemRowBackground"
          :expanded.sync="expanded"
          hide-default-footer
          mobile-breakpoint
          dense
        >
          <!-- campos editables -->
          <template v-slot:[`item.cantidad`]="{ item }">
            <v-text-field
              v-if="item.gift_card != 1"
              v-model.trim="item.cantidad"
              type="number"
              class="py-1"
              hide-details
              outlined
              dense
              @change="validarCantidades(item)"
            ></v-text-field>
            <span v-else>
              {{ item.cantidad }}
            </span>
          </template>
          <template v-slot:[`item.precio`]="{ item }">
            <text-field-money
              v-if="modifica_precio == 1 && item.gift_card != 1"
              v-model="item.precio"
              class="py-1"
              v-bind:properties="{
                prefix: '$',
                'hide-details': true
              }"
              @change="recalcularImporteArticulo(item, 1)"
            />
            <span v-else>
              {{ formatMoney(item.precio) }}
            </span>
          </template>
          <template v-slot:[`item.combo`]="{ item }">
            <v-autocomplete
              v-model="item.combo"
              :items="[1,2,3,4,5]"
              hide-details
              outlined
              dense
            ></v-autocomplete>
          </template>
          <!-- campos con formato -->
          <template v-slot:[`item.precio_lista`]="{ value }">
            {{ formatMoneyRound(value) }}
          </template>
          <template v-slot:[`item.importe`]="{ value }">
            {{ formatMoney(value) }}
          </template>
          <template v-slot:[`item.importe_gift`]="{ value }">
            {{ formatMoney(value) }}
          </template>
          <template v-slot:[`item.descuento`]="{ item }">
            {{ item.descuento.toFixed(parseFloat(item.descuento) % 1 == 0 || parseFloat(item.descuento) == 0 ? 0 : 2) }} %
            <v-tooltip
              v-if="item.descuento > 0 && promociones.find(pro => pro.articulo == item.codigo)"
              color="primary"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="info"
                  small
                >
                  fas fa-info-circle
                </v-icon>
              </template>
              <div
                v-for="(promo, index) in promociones.find(pro => pro.articulo == item.codigo).detalle"
                :key="index"
                style="width: 400px;"
              >
                <v-row style="width: 100%;" no-gutters>
                  <v-col cols="10" class="font-weight-bold">
                    {{ promo.nombre.substring(0, 38) }}
                  </v-col>
                  <v-col cols="2" class="d-flex justify-end">
                    <strong>
                      {{ promo.porcentaje.toFixed(parseFloat(promo.porcentaje) % 1 == 0 || parseFloat(promo.porcentaje) == 0 ? 0 : 2) }} %
                    </strong>
                  </v-col>
                </v-row>
              </div> 
            </v-tooltip>
          </template>
          <!-- acciones -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              color="error"
              title="Quitar"
              style="margin-right: 6px;"
              small
              @click="quitarArticulo(item)"
            >
              fas fa-times-circle
            </v-icon>
            <v-icon
              v-if="item.solicita_serie == 1"
              color="cyan"
              title="Series"
              style="margin-right: 6px;"
              small
              @click="articulo_select = item; modal_series = true"
            >
              fas fa-th-list
            </v-icon>
            <v-icon
              v-if="modifica_precio == 1"
              color="orange"
              title="Agregar info"
              style="margin-right: 6px;"
              small
              @click="articulo_select = item; modal_info_adicional = true"
            >
              fas fa-edit
            </v-icon>
            <v-icon
              v-if="item.financiaciones_especiales.length > 1"
              color="success"
              title="Agregar financiación esp."
              style="margin-right: 6px;"
              small
              @click="desglosarFinEsp(item)"
            >
              fas fa-plus
            </v-icon>
            <v-icon
              v-if="item.gift_card == 1"
              color="green"
              title="Ver Gift Card"
              style="margin-right: 6px;"
              small
              @click="openGF(item)"
            >
              fas fa-gift
            </v-icon>
          </template>
          <!-- no datos -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="600"
              type="info"
              border="left"
              dense
              text
            >
              Agregue artículos por IMEI (serie), código de Barra o código Interno
            </v-alert>
          </template>
          <!-- FINANCIACIONES ESPECIALES (del articulo) -->
          <template v-slot:[`item.financing`]="{ item }">
            <v-autocomplete
              v-if="item.financiaciones_especiales.length > 0 && expanded.filter(art => art.codigo == item.codigo).length == 0"
              v-model="item.financiacion"
              :items="item.financiaciones_especiales"
              item-text="forma_pago_nombre"
              return-object
              hide-details
              clearable
              outlined
              dense
              @change="armarPreseleccionado(item.financiacion)"
            ></v-autocomplete>
          </template>
          <!-- expandible (financiaciones especiales) -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div class="d-flex justify-end py-2 mr-4">
                <v-card style="width: 85%;" flat>
                  <v-data-table
                    class="tableVta"
                    :items="item.financiaciones"
                    :headers="headersFinEsp"
                    :items-per-page="-1"
                    hide-default-footer
                    dense
                  >
                    <template v-slot:[`item.financiacion`]="{ item }">
                      <v-autocomplete
                        v-model="item.financiacion"
                        :items="item.financiaciones_especiales"
                        item-text="forma_pago_nombre"
                        return-object
                        hide-details
                        outlined
                        dense
                        @change="calcularCuotasFinEsp(item)"
                      ></v-autocomplete>
                    </template>
                    <template v-slot:[`item.monto`]="{ item }">
                      <text-field-money
                        v-model="item.monto"
                        class="py-1"
                        v-bind:properties="{
                          prefix: '$',
                          'hide-details': true
                        }"
                        @change="calcularCuotasFinEsp(item)"
                      />
                    </template>
                    <!-- campos calculados -->
                    <template v-slot:[`item.cuota`]="{ value }">
                      {{ formatMoney(value) }}
                    </template>
                    <template v-slot:[`item.tarjeta_nombre`]="{ item }">
                      {{ Object.keys(item.financiacion).length != 0 ? item.financiacion.tarjeta_nombre : '' }}
                    </template>
                    <template v-slot:[`item.cuota_nombre`]="{ item }">
                      {{ Object.keys(item.financiacion).length != 0 ? item.financiacion.cuota_nombre : '' }}
                    </template>
                    <template v-slot:[`item.total`]="{ value }">
                      {{ formatMoney(value) }}
                    </template>
                    <!-- acciones -->
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip
                        v-if="Object.keys(item.financiacion).length != 0"
                        bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            color="info"
                            class="mr-2"
                            small
                          >
                            fas fa-info-circle
                          </v-icon>
                        </template>
                        <span>
                          Alícuota: {{ item.financiacion.porcentaje.toFixed(2) }}%
                        </span>
                      </v-tooltip>
                      <v-icon
                        color="error"
                        title="Quitar"
                        small
                        @click="quitarFinEsp(item)"
                      >
                        fas fa-times-circle
                      </v-icon>
                    </template>
                    <!-- total -->
                    <template slot="body.append">
                      <tr class="font-weight-bold">
                        <th style="font-size: 14px" >Total financiado</th>
                        <th></th>
                        <th></th>
                        <th style="font-size: 14px" class="text-right">
                          {{ formatMoney(item.financiaciones.reduce((sum, fin) => sum + fin.monto, 0)) }}
                        </th>
                        <th></th>
                        <th style="font-size: 14px" class="text-right">
                          {{ formatMoney(item.financiaciones.reduce((sum, fin) => sum + fin.total, 0)) }}
                        </th>
                        <th></th>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
              </div>
            </td>
          </template>
          <!-- fila del total de articulos -->
          <template slot="body.append">
            <tr class="font-weight-bold">
              <th style="font-size: 14px" >Subtotal</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th v-if="giftcard"></th>
              <th style="font-size: 16px" class="text-right">
                {{ formatMoney(this.monto_articulos) }}
              </th>
              <th v-if="combo"></th>
              <th></th>
              <th></th>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </template>
    <!-- ---------------------------- -->
    <!-- COMPONENTE DE FORMAS DE PAGO -->
    <!-- ---------------------------- -->
    <template>
      <!-- FILTRO FP -->
      <v-col cols="12">
        <v-divider class="py-3"></v-divider>
        <v-card color="primary" class="mb-4">
          <v-row class="d-flex align-center" style="font-size: 18px;">
            <v-col cols="12" sm="4" md="3" lg="2" class="d-flex ml-3 white--text">
              <v-icon dark left>fas fa-dollar-sign</v-icon>
              Formas de Pago
            </v-col>
            <v-col cols="4" sm="2" class="d-flex justify-sm-end pt-0 pb-3 pb-sm-0 ml-3 ml-sm-0">
              <v-checkbox
                v-model="efectivo"
                class="mt-0 mr-sm-6 mr-0"
                label="Efectivo"
                color="white"
                tabindex="1"
                hide-details
                dense
                dark
                @click="debito ? debito = false : ''"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" sm="4" lg="3" xl="2" class="d-flex justify-start pt-0 pb-3 pb-sm-0 ml-md-0 ml-n3">
              <v-checkbox
                v-model="debito"
                class="mt-0 mr-3"
                label="Débito/Transferencia"
                color="white"
                tabindex="1"
                hide-details
                dense
                dark
                @click="efectivo ? efectivo = false : ''"
                @change="!efectivo ? getDescuentos() : ''"
              ></v-checkbox>
            </v-col>
            <v-col cols="4" sm="2" class="d-flex justify-start pt-0 pb-3 pb-sm-0 ml-sm-n3 ml-3 pr-0">
              <v-checkbox
                v-model="giftcard"
                class="mt-0 mr-3"
                label="Gift Card"
                color="white"
                tabindex="1"
                :disabled="articulos.filter(art => art.gift_card == 1).length > 0"
                hide-details
                dense
                dark
              ></v-checkbox>
            </v-col>
            <v-expand-transition>
              <div
                v-if="giftcard"
                :style="$vuetify.breakpoint.xs ? ('width: 50%;') : (
                        $vuetify.breakpoint.sm ? ('width: 33%;') : (
                        $vuetify.breakpoint.md ? ('width: 25%;') : (
                        $vuetify.breakpoint.lg ? ('width: 25%;') : 'width: 16%;'
                )))"
              >
                <v-col cols="12" class="d-flex justify-start mt-n4 mt-sm-n1 mb-n1 pl-sm-6 pl-0 pr-sm-6 pr-0">
                  <v-text-field
                    v-model.trim="giftcard_codigo"
                    label="Gift Card"
                    color="swalText"
                    style="background: var(--v-swalBkg-base);"
                    hide-details
                    outlined
                    dense
                    @change="validarGF()"
                  ></v-text-field>
                </v-col>
              </div>
            </v-expand-transition>
          </v-row>
        </v-card>
        <v-expand-transition>
          <div v-if="!efectivo">
            <v-form @submit.prevent="agreagrFormaPago()" class="pt-8">
              <v-row>
                <v-col cols="6" sm="3" md="2" class="py-0">
                  <text-field-money
                    v-model="monto"
                    label="Monto"
                    class="py-0"
                    v-bind:properties="{
                      prefix: '$',
                      tabindex: 1
                    }"
                    @change="simularFormaPago()"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-autocomplete
                    v-model="forma_pago"
                    label="Forma pago"
                    item-value="forma_pago_id"
                    item-text="forma_pago_nombre"
                    tabindex="1"
                    :items="financiaciones_disponibles"
                    :readonly="monto == null || parseFloat(monto) <= 0"
                    :filled="monto == null || parseFloat(monto) <= 0"
                    return-object
                    clearable
                    outlined
                    dense
                    @change="simularFormaPago()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-0">
                  <text-field-money
                    v-model="monto_cuotas"
                    label="Monto Cuotas"
                    class="py-0 pr-2"
                    v-bind:properties="{
                      prefix: '$',
                      readonly: true,
                      filled: true
                    }"
                  />
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-0 pl-0 d-flex align-start">
                  <text-field-money
                    v-model="fp_importe"
                    label="Importe"
                    class="py-0"
                    v-bind:properties="{
                      prefix: '$',
                      readonly: true,
                      filled: true
                    }"
                  />
                  <v-tooltip
                    v-if="Object.keys(forma_pago).length != 0"
                    color="primary"
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="info"
                        class="pt-1 ml-2"
                      >
                        fas fa-info-circle
                      </v-icon>
                    </template>
                    <div
                      v-for="(item, index) in simulacion"
                      :key="index"
                      style="width: 500px;"
                    >
                      <v-row style="width: 100%;" no-gutters>
                        <v-col cols="7" class="font-weight-bold">
                          {{ item.nombre.substring(0, 38) }}
                        </v-col>
                        <v-col cols="3" class="d-flex justify-end">
                          <strong class="mr-4">{{ formatMoney(item.importe) }}</strong>
                        </v-col>
                        <v-col cols="2">
                          Alic: {{ item.alicuota.toFixed(2) }} %
                        </v-col>
                      </v-row>
                    </div> 
                  </v-tooltip>
                </v-col>
                <v-col v-if="bancarizada" cols="5" sm="4" md="2" class="py-0">
                  <v-checkbox
                    v-model="bancarizada"
                    class="mt-0"
                    label="Bancarizada"
                    tabindex="1"
                    hide-details
                    readonly
                    dense
                  ></v-checkbox>
                </v-col>
                <v-col v-if="bancarizada" cols="7" sm="4" md="3" class="py-0">
                  <v-autocomplete
                    v-model="banco"
                    label="Banco"
                    item-text="banco_id"
                    item-value="banco_pais_nombre"
                    tabindex="1"
                    :items="bancos"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="2" class="d-flex justify-end pt-0">
                  <v-btn
                    color="info"
                    type="submit"
                    tabindex="1"
                    style="margin-top: -2px;"
                  >
                    Agregar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-expand-transition>
      </v-col>
      <v-expand-transition>
        <div v-if="monto_efectivo < 0">
          <v-alert
            type="error"
            border="left"
          >
            Existen diferencias entre las formas de pago ingresadas y el total de la venta. Verificar
          </v-alert>
        </div>
      </v-expand-transition>
      <!-- LISTA DE FORMAS DE PAGO -->
      <v-expand-transition>
        <div v-if="!efectivo" style="width: 100%;">
          <v-col cols="12" class="pt-0">
            <v-data-table
              class="elevation-2 cebra"
              :items="formas_pago_group"
              :headers="headersFP"
              :items-per-page="-1"
              hide-default-footer
              mobile-breakpoint
              dense
            >
              <!-- checks boxs -->
              <template v-slot:[`item.cobro_online`]="{ item }">
                <div class="d-flex align-center">
                  <v-spacer></v-spacer>
                  <v-checkbox
                    v-model="item.cobro_online"
                    class="mb-1"
                    :true-value="1"
                    :false-value="0"
                    :disabled="item.id != null || item.estado_solicitud == 2 || item.solicita_banco == 1 || ptovta.cobro_electronico == 0"
                    hide-details
                    dense
                    @click="setSelectedCheck(item, 1)"
                  ></v-checkbox>
                  <v-spacer></v-spacer>
                </div>
              </template>
              <template v-slot:[`item.lapos_integrado`]="{ item }">
                <div class="d-flex align-center">
                  <v-spacer></v-spacer>
                  <v-checkbox
                    v-model="item.lapos_integrado"
                    class="mb-1"
                    :true-value="1"
                    :false-value="0"
                    :disabled="item.id != null || item.estado_solicitud == 2 || item.solicita_banco == 1 || ptovta.fp_integrado == 0 ||
                              (ptovta.fp_integrado == 1 && ptovta.fp_desintegrado == 0)"
                    hide-details
                    dense
                    @click="setSelectedCheck(item, 2)"
                  ></v-checkbox>
                  <v-spacer></v-spacer>
                </div>
              </template>
              <template v-slot:[`item.qr`]="{ item }">
                <v-checkbox
                  v-model="item.qr"
                  class="mb-1"
                  :true-value="1"
                  :false-value="0"
                  :disabled="item.id != null || item.cobro_online == 1 || item.lapos_integrado == 0 || ptovta.fp_integrado == 0"
                  hide-details
                  dense
                ></v-checkbox>
              </template>
              <!-- campos con formato -->
              <template v-slot:[`item.monto`]="{ value }">
                {{ formatMoney(value) }}
              </template>
              <template v-slot:[`item.monto_cuotas`]="{ value }">
                {{ formatMoney(value) }}
              </template>
              <template v-slot:[`item.total`]="{ value }">
                {{ formatMoney(value) }}
              </template>
              <!-- acciones -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip color="primary" left>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="info"
                      class="mr-4"
                      small
                    >
                      fas fa-info-circle
                    </v-icon>
                  </template>
                  <div
                    v-for="(detalle, index) in formas_pago.filter(fp => fp.id_interno == item.id_interno)"
                    :key="index"
                    style="width: 500px;"
                  >
                    <v-row style="width: 100%;" no-gutters>
                      <v-col cols="7" class="font-weight-bold">
                        {{ detalle.forma_pago_nombre.substring(0, 38) }}
                      </v-col>
                      <v-col cols="3" class="d-flex justify-end">
                        <strong class="mr-4">{{ formatMoney(detalle.monto) }}</strong>
                      </v-col>
                      <v-col cols="2">
                        Alic: {{ detalle.porcentaje.toFixed(2) }} %
                      </v-col>
                    </v-row>
                  </div>
                </v-tooltip>
                <v-icon
                  color="info"
                  title="Detalle Forma Pago"
                  class="mr-2"
                  small
                  @click="fp_selected = item; modal_fp = true"
                >
                  fas fa-list
                </v-icon>
                <v-icon
                  color="error"
                  title="Quitar"
                  class="mr-2"
                  small
                  @click="quitarFP(item)"
                >
                  fas fa-times-circle
                </v-icon>
                <v-icon
                  v-if="!item.completa"
                  color="orange"
                  title="Faltan completar datos"
                  style="cursor: pointer;"
                  :class="`pulse-${$vuetify.theme.dark ? 'dark' : 'light'}`"
                  small
                  @click="fp_selected = item; modal_fp = true"
                >
                  fas fa-exclamation-triangle
                </v-icon>
                <v-icon
                  v-if="item.estado_solicitud == 2 && item.cobro_online == 1"
                  color="indigo"
                  title="Imprimir Cupón"
                  class="mr-2"
                  small
                >
                  fas fa-print
                </v-icon>
                <v-icon
                  v-if="((item.cobro_online == 1 || item.lapos_integrado == 1) && item.error_id != 0) ||
                        (item.lapos_integrado == 1 && item.estado_solicitud != 2 && item.estado_solicitud != 4 && item.id_solicitud != null)"
                  color="error"
                  title="Error"
                  :class="`pulse-${$vuetify.theme.dark ? 'dark' : 'light'}`"
                  small
                >
                  fas fa-exclamation-circle
                </v-icon>
              </template>
              <!-- fila del total de fp -->
              <template slot="body.append">
                <tr class="font-weight-bold">
                  <th style="font-size: 14px" >Subtotal</th>
                  <th></th>
                  <th></th>
                  <th style="font-size: 14px" class="text-right">
                    {{ formatMoney(monto_fp) }}
                  </th>
                  <th></th>
                  <th></th>
                  <th style="font-size: 14px" class="text-right">
                    {{ formatMoney(monto_fp_alic) }}
                  </th>
                  <th></th>
                </tr>
              </template>
              <!-- no datos -->
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="450"
                  type="info"
                  border="left"
                  dense
                  text
                >
                  No se agregaron Formas de Pago
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
        </div>
      </v-expand-transition>
      <!-- TOTALES FP Y TERMINAL LAPOS -->
      <v-col cols="12">
        <v-row>
          <v-col cols="8" sm="4" md="3" class="mb-4">
            <v-expand-transition>
              <v-autocomplete
                v-if="ptovta.fp_integrado == 1 && !efectivo"
                v-model="terminal"
                label="Terminal"
                style="background: var(--v-rowwar-base);"
                item-text="nombre"
                item-value="numero"
                tabindex="1"
                :items="terminales"
                hide-details
                clearable
                outlined
                dense
              ></v-autocomplete>
            </v-expand-transition>
          </v-col>
          <v-col cols="12" sm="6" md="7" xl="8" class="pt-0">
            <v-row class="d-flex justify-end font-weight-medium" style="font-size: 18px; cursor: default;">
              <v-col cols="6" xl="3" class="d-flex align-center">
                <v-icon color="success" left>fas fa-money-bill-alt</v-icon>
                Efectivo
              </v-col>
              <v-col cols="6" md="2" class="d-flex justify-end mr-md-6 mr-0">
                {{ formatMoney(monto_efectivo) }}
              </v-col>
            </v-row>
            <v-row class="d-flex justify-end font-weight-bold primary--text" style="font-size: 20px; cursor: default;">
              <v-col cols="6" xl="3" class="d-flex align-center pt-0">
                <v-icon left>fas fa-hand-holding-usd</v-icon>
                Total
              </v-col>
              <v-col cols="6" md="2" class="d-flex justify-end mr-md-6 mr-0 pt-0">
                {{ formatMoney(monto_efectivo + monto_fp_alic) }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </template>
    <!-- ------------------------------- -->
    <!-- DATOS DEL CLIENTE -->
    <!-- ------------------------------- -->
    <modulo-cliente
      :p_cliente.sync="cliente"
      :p_cliente_escaneado.sync="cliente_escaneado"
      :p_local="ptovta"
      :p_init="init_clientes"
      @descuento="getDescuentos"
      @setNuevo="setNuevoClinte"
    />
    <!-- ------- -->
    <!-- BOTONES -->
    <!-- ------- -->
    <v-col cols="12" class="d-flex justify-end pt-4">
      <v-btn
        color="error"
      >
        Cancelar
      </v-btn>
      <v-btn
        color="success"
        class="ml-3"
        tabindex="1"
        :disabled="articulos.length == 0"
        @click="grabarVenta()"
      >
        <v-icon small left>fas fa-save</v-icon>
        Grabar
      </v-btn>
    </v-col>
    <!-- --------------------- -->
    <!-- COMPONENTES (MODALES) -->
    <!-- --------------------- -->
    <buscar-articulo
      v-model="modal_articulos"
      :p_lista="precios"
      @selected="seleccionarArticulo"
    />
    <buscar-vendedor
      v-model="modal_vendedores"
      :p_lista="vendedores"
      @selected="seleccionarVendedor"
    />
    <seleccionar-serie
      v-model="modal_series"
      :p_articulo="articulo_select"
      @confirmar="setSeries"
      @cancelar="cancelarSeries"
    />
    <modal-forma-pago
      v-model="modal_fp"
      :p_fp.sync="fp_selected"
      :p_ptovta="ptovta"
      :p_init="init_fp"
    />
    <modal-gift-card
      v-model="modal_gf"
      :p_gf="art_gf"
      @nueva="newGF"
      @actualizar="updateGF"
    />
    <!-- modal para seleccionar 1 art si encuentra varios -->
    <v-dialog
      v-model="modal_select_art"
      width="600"
      scrollable
    >
      <v-card>
        <v-card-title>
          Seleccionar artículo
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="modal_select_art = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0">
          <v-data-table
            class="cebra"
            sort-by="nombre"
            :items="select_arts"
            :headers="[
              { text: 'Código', value: 'codigo', align: 'end', sortable: false, filterable: false, },
              { text: 'Nombre', value: 'nombre', sortable: false, filterable: false, },
              { text: 'Acciones', value: 'actions', sortable: false, filterable: false, align: 'center' }
            ]"
            :items-per-page="-1"
            hide-default-footer
            dense
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                color="success"
                title="Seleccionar"
                small
                @click="seleccionarArticulo(item)"
              >
                fas fa-check
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- modal informacion adicional del articulo -->
    <v-dialog
      v-model="modal_info_adicional"
      width="500"
      scrollable
    >
      <v-card>
        <v-card-title style="word-break: normal;">
          {{ articulo_select.nombre }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="modal_info_adicional = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-6 pb-0">
          <v-form ref="formInfoAdicional">
            <v-textarea
              v-model.trim="info_adicional"
              label="Información adicional"
              counter="200"
              rows="3"
              :rules="[rules.max]"
              validate-on-blur
              auto-grow
              outlined
              dense
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="error"
            class="mr-2"
            @click="modal_info_adicional = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            :disabled="info_adicional ? info_adicional.length > 200 : false"
            @click="setInfoAdicional()"
          >
            <v-icon small left>fas fa-check</v-icon>
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { format_money, format_money_round, order_list_by, roundNumberTF } from '../util/utils'
import TextFieldMoney from '../components/util/TextFieldMoney.vue'
import BuscarArticulo from '../components/ventas/venta/BuscarArticulo.vue'
import BuscarVendedor from '../components/ventas/venta/BuscarVendedor.vue'
import ModuloCliente from '../components/ventas/venta/ModuloCliente.vue'
import SeleccionarSerie from '../components/ventas/venta/SeleccionarSerie.vue'
import ModalFormaPago from '../components/ventas/venta/ModalFormaPago.vue'
import ModalGiftCard from '../components/ventas/venta/ModalGiftCard.vue'

export default {
  data () {
    return {
      formatMoney: format_money,
      formatMoneyRound: format_money_round,
      modal_articulos: false,
      modal_select_art: false,
      modal_vendedores: false,
      modal_info_adicional: false,
      modal_series: false,
      modal_fp: false,
      modal_gf: false,
      anula_dias_mes: 0,
      importe_max_b: 0,
      venta_min: 0,
      redondeo: 0,
      empleado: 0,
      modifica_precio: 0,
      combo: false,
      debito: false,
      efectivo: false,
      giftcard: false,
      sucursal: null,
      ptovta: {},
      terminal: null,
      articulo: null,
      vendedor: null,
      vendedor_acc: null,
      vendedor_nombre: null,
      giftcard_codigo: '',
      cupon: null,
      id_fin: 0,
      monto_cuotas: 0,
      fp_importe: 0,
      forma_pago: {},
      bancarizada: 0,
      banco: null,
      monto: null,
      monto_articulos: 0,
      monto_efectivo: 0,
      monto_fp_alic: 0,
      monto_fp: 0,
      subtotal_fp: 0,
      lista_desc: null,
      info_adicional: '',
      stock_giftcard: [],
      art_gf: {},
      cliente: {},
      cliente_nuevo: false,
      cliente_escaneado: {},
      articulo_select: {},
      fp_selected: {},
      precios: [],
      promociones: [],
      select_arts: [],
      simulacion: [],
      terminales: [],
      vendedores: [],
      articulos: [],
      expanded: [],
      bancos: [],
      cuentas: [],
      localidades: [],
      formas_pago: [],
      formas_pago_group: [],
      financiaciones_especiales: [],
      financiaciones_especiales_articulos: [],
      financiaciones_disponibles: [],
      headersArt: [
        { text: 'Cant.', value: 'cantidad', sortable: false, filterable: false, width: '65', align: 'right' },
        { text: 'Cod. Interno', value: 'codigo', sortable: false, filterable: false, width: '1', align: 'right' },
        { text: 'Artículo', value: 'nombre', sortable: false, filterable: false },
        { text: 'Precio lista', value: 'precio_lista', sortable: false, filterable: false, width: '1', align: 'right' },
        { text: 'Desc.', value: 'descuento', sortable: false, filterable: false, width: '85', align: 'right' },
        { text: 'Precio', value: 'precio', sortable: false, filterable: false, width: '135', align: 'right' },
        { text: 'Importe', value: 'importe', sortable: false, filterable: false, width: '1', align: 'right' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false, width: '1' },
        { text: 'Financiación especial', value: 'financing', sortable: false, filterable: false }
      ],
      headersFinEsp: [
        { text: 'Financiación', value: 'financiacion', sortable: false, filterable: false },
        { text: 'Tarjeta', value: 'tarjeta_nombre', sortable: false, filterable: false, width: '110' },
        { text: 'Cuota', value: 'cuota_nombre', sortable: false, filterable: false, width: '90' },
        { text: 'Importe', value: 'monto', sortable: false, filterable: false, align: 'right', width: '135' },
        { text: 'Valor cuota', value: 'cuota', sortable: false, filterable: false, align: 'right', width: '1' },
        { text: 'Total', value: 'total', sortable: false, filterable: false, align: 'right', width: '1' },
        { text: '', value: 'actions', sortable: false, filterable: false, align: 'end', width: '56' }
      ],
      headersFP: [
        { text: 'Online', value: 'cobro_online', sortable: false, filterable: false, align: 'center', width: '1' },
        { text: 'Integrado', value: 'lapos_integrado', sortable: false, filterable: false, align: 'center', width: '1' },
        { text: 'QR', value: 'qr', sortable: false, filterable: false, align: 'center', width: '1' },
        { text: 'Monto', value: 'monto', sortable: false, filterable: false, width: '1', align: 'right' },
        { text: 'Forma pago', value: 'descripcion', sortable: false, filterable: false },
        { text: 'Monto cuotas', value: 'monto_cuotas', sortable: false, filterable: false, width: '100', align: 'right' },
        { text: 'Total', value: 'total', sortable: false, filterable: false, width: '1', align: 'right' },
        { text: '', value: 'actions', sortable: false, filterable: false, width: '180' }
      ],
      locales_user: [],
      init_clientes: {
        provincias: [],
        tipos_doc: [],
        cond_iva: []
      },
      init_fp: {
        tipos_mov: [],
        cuentas: [],
        bancos: []
      },
      datosFPLimpio : {
        codigo_autorizacion: '',
        cuotas: '',
        cuotas_importe: '',
        tarjeta_numero: '',
        tarjeta_codigo: null,
        terceros: false,
        cliente: false,
        titular: '',
        titular_dni: '',
        imei_cotizado: '',
        descuento: '',
        cupon: '',
        lote: '',
        fecha_cobro: null,
        fecha_cupon: null,
        banco_codigo: null,
        banco_cuenta: null,
        banco_fecha: null,
        banco_tipo_movimiento: null,
        banco_comprobante: '',
        tarjeta_vencimiento: '',
        codigo_seguridad: '',
        cobro_transaccion: ''
      },
      rules: {
        max: value => (value ? value.length : 0) <= 200 || 'Máximo 200 caracteres'
      }
    }
  },
  async created () {
    await new Promise(resolve => setTimeout(resolve, 1))
    this.$store.state.loading = true
    await this.$store.dispatch('tarjetas/get_tarjetas_habilitadas')
    let result = await this.$store.dispatch('ventas/init_form')
    this.$store.state.loading = false

    if (result.exito == 1) {
      const datos = result.data
      this.locales_user = datos.locales.data
      this.bancos = datos.bancos_pais.data
      this.init_clientes = {
        provincias: datos.provincias.data,
        tipos_doc: datos.tipos_doc.data,
        cond_iva: datos.cond_iva.data
      }
      this.init_fp = {
        tipos_mov: datos.tipos_mov.data,
        cuentas: datos.cuentas.data,
        bancos: []
      }
      this.anula_dias_mes = datos.parametros.anula_dias_mes
      this.importe_max_b = datos.parametros.importe_max_b
      this.venta_min = datos.parametros.venta_min
      this.redondeo = datos.parametros.redondeo
      this.modifica_precio = datos.funciones.modifica_precio
    } else {
      this.$store.dispatch('show_snackbar', {
        text: result.message,
        color: 'error'
      })
    }

    if (this.una_sucursal) {
      this.sucursal = this.sucursales[0].id
      this.$refs.atcLocal.focus()
    } else {
      this.$refs.atcSucursal.focus()
    }
  },
  computed: {
    ...mapGetters(['una_sucursal']),
    ...mapState(['sucursales'])
  },
  components: {
    TextFieldMoney,
    BuscarArticulo,
    BuscarVendedor,
    ModuloCliente,
    SeleccionarSerie,
    ModalFormaPago,
    ModalGiftCard
  },
  watch: {
    forma_pago: {
      handler: function (val) {
        if (val == null) this.forma_pago = {}
      },
      deep: true
    },
    ptovta: {
      handler: async function () {
        this.limpiar()
        if (this.ptovta == null) {
          this.ptovta = {}
          return
        }
        if (Object.keys(this.ptovta).length != 0) {
          this.$store.state.loading = true
          let result = await this.$store.dispatch('ventas/ptovta_data', {
            empresa: this.ptovta.empresa,
            sucursal: this.ptovta.sucursal,
            local: this.ptovta.id,
            local_acc: this.ptovta.local_accesorios,
            canal: this.ptovta.canal
          })
          this.$store.state.loading = false

          if (result.exito == 1) {
            const datos = result.data
            this.precios = datos.precios.data
            this.terminales = datos.terminales.data
            this.vendedores = datos.vendedores.data
            this.financiaciones_disponibles = datos.financiaciones.data
            this.init_fp.bancos = datos.bancos.data
          } else {
            this.$store.dispatch('show_snackbar', {
              text: result.message,
              color: 'error'
            })
          }
          order_list_by(this.financiaciones_disponibles, 'forma_pago_nombre')

          // si ya usaron una terminal, veo si puedo setearla
          if (localStorage.getItem('terminal_def')) {
            let num_terminal = JSON.parse(localStorage.getItem('terminal_def'))
            // determino si se encuentra en mi listado de terminales
            let terminal = this.terminales.find(ter => ter.numero == num_terminal)
            this.terminal = terminal ? terminal.numero : null
          }
        }
      },
      deep: true
    },
    articulos: {
      handler: function () {
        this.calcularTotales()
      },
      deep: true
    },
    formas_pago_group: {
      handler: function () {
        this.calcularTotales()
      },
      deep: true
    },
    efectivo (val) {
      if (val) {
        this.limpiarFP()
        this.terminal = null
        this.formas_pago = []
        this.formas_pago_group = []
      }
      this.getDescuentos()
    },
    giftcard (val) {
      if (val) {
        this.headersArt.splice(6, 0, { text: 'Imp. Gift', value: 'importe_gift', sortable: false, filterable: false, width: '1' })
      } else {
        this.limpiarGF()
        this.headersArt.splice(6, 1)
      }
      this.getDescuentos()
    },
    combo (val) {
      const cantidad = this.giftcard ? 8 : 7
      if (val) {
        this.headersArt.splice(cantidad, 0, { text: 'Combo', value: 'combo', sortable: false, filterable: false, width: '75' })
        for (let art of this.articulos) {
          art.combo = 1
        }
      } else {
        this.headersArt.splice(cantidad, 1)
        for (let art of this.articulos) {
          art.combo = null
        }
      }
    },
    modal_info_adicional (val) {
      if (val) {
        this.info_adicional = JSON.parse(JSON.stringify(this.articulo_select.info_adicional))
      } else {
        this.$refs.formInfoAdicional.resetValidation()
        this.articulo_select = {}
        this.info_adicional = ''
      }
    }
  },
  methods: {
    /****************
      VENTA Y LAPOS INTEGRADO
     ****************/
    async grabarVenta () {

      // muestro el modal de confirmación
      let modal = await this.$swal.fire({
        title: 'Confirmar Venta',
        html: `
        <span style="font-size: 18px">Desea confirmar la venta por un total de:</span>
        <table style="width: 100%; margin-top: 15px; ${this.$vuetify.breakpoint.xs ? '' : 'padding: 0px 20px'}">
          <tr style="height: 30px">
            <th style="font-size: 18px; text-align: left">Total Efectivo</th>
            <th style="font-size: 20px; text-align: right">${format_money(this.monto_efectivo)}</th>
          </tr>
          <tr style="height: 30px">
            <th style="font-size: 18px; text-align: left">Total Tarjeta</th>
            <th style="font-size: 20px; text-align: right">${format_money(this.monto_fp_alic)}</th>
          </tr>
        </table>
        <div style="margin${this.$vuetify.breakpoint.xs ? '-top: 15px' : ': 15px 15px 0px 15px'}; border: 1px solid"></div>
        <table style="width: 100%; ${this.$vuetify.breakpoint.xs ? '' : 'padding: 0px 20px'}">
          <tr style="height: 50px">
            <th style="font-size: 20px; text-align: left">Total</th>
            <th style="font-size: 22px; text-align: right">${format_money(this.monto_efectivo + this.monto_fp_alic)}</th>
          </tr>
        </table>
        `,
        width: '450px',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        allowEnterKey: false
      })
      if (!modal.isConfirmed) return

      // empiezo con las validaciones ¬¬
      const titulo = 'Error al grabar la Venta'
      const cliente_valido = await this.validarCliente()

      // valido que el vendedor no sea null
      if (!this.vendedor) {
        this.$swal.fire({
          icon: 'warning',
          title: titulo,
          text: 'Debe seleccionar un vendedor para poder grabar la venta'
        })
        return
      }

      // valido que no le tenfa q pagar yo al cliente xd
      if (roundNumberTF(this.monto_efectivo) < 0) {
        this.$swal.fire({
          icon: 'warning',
          title: titulo,
          text: 'Existen diferencias en las Formas de Pago. Verificar'
        })
        return
      }

      // verifico q si no puso fp tilde efectivo
      // puede ser q page con gf, en ese caso el efectivo puede no estar tildado solo si no hay efectivo xd
      if ((this.formas_pago_group.length == 0 && !this.efectivo) || (this.formas_pago_group.length == 0 && !this.efectivo && this.giftcard && roundNumberTF(this.monto_efectivo) > 0)) {
        this.$swal.fire({
          icon: 'warning',
          title: titulo,
          text: 'No se agregaron Formas de Pago, seleccione Efectivo'
        })
        return
      }

      // si es un cliente nuevo y cuit y num_doc son nulos es pq no completo nada >:v
      if (this.cliente_nuevo && !this.cliente.cuit && !this.cliente.num_doc) {
        this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'Complete los campos del cliente'
          })
          return
      }

      // si ingreso un cliente verifico q envie la condicion de iva y el tipo de doc
      if (!!this.cliente.cuit || !!this.cliente.num_doc) {
        if (!this.cliente.cond_iva || !this.cliente.tipo_doc) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'Debe ingresar Condición de IVA y Tipo Documento del cliente'
          })
          return
        }
        // tmb tiene q ingresar los nombres, q no se haga el loco
        if (!this.cliente.apellido || !this.cliente.nombre) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'Debe ingresar Apellido y Nombre del cliente'
          })
          return
        }
        // si el tipo de doc es CUIT verifico q sean 11 caracteres
        if (this.cliente.tipo_doc == 1 && (!this.cliente.cuit || this.cliente.cuit.toString().length != 11)) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'El CUIT del cliente debe tener 11 dígitos'
          })
          return
        }
      }

      // si la venta supera el monto minimo o si al menos 1 art solicita datos, valido los datos del cliente
      if (this.monto_articulos >= this.venta_min || this.articulos.filter(art => art.solicita_datos == 1).length > 0) {
        if (cliente_valido) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: `La venta tiene un monto de ${format_money(this.monto_articulos)} es obligatorio completar los datos del cliente`
          })
          return
        }
        if (!this.cliente.email || !this.cliente.telefono) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: `La venta tiene un monto de ${format_money(this.monto_articulos)} es obligatorio Email y Teléfono`
          })
          return
        }
      }

      // verificio si agrego una forma de pago
      if (this.formas_pago_group.length > 0) {
        if (cliente_valido) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'La venta contiene formas de pago en tarjeta. Debe ingresar Nº Documento, Condición de IVA, Tipo Documento, Apellido y Nombre'
          })
          return
        }
        if (!this.cliente.nombre || !this.cliente.apellido) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'La venta contiene formas de pago en tarjeta. Debe ingresar Apellido y Nombre'
          })
          return
        }
      }

      // valido las fp
      if (this.formas_pago_group.filter(fpg => !fpg.completa).length > 0) {
        this.$swal.fire({
          icon: 'warning',
          title: titulo,
          text: 'Existen formas de pago con los datos incompletos. Verificar'
        })
        return
      }

      // controlo que coloque si o si el cliente cuando al menos 1 detalle de forma de pago sea lapos integrado y esté marcado como CLIENTE
      if (cliente_valido && this.formas_pago_group.filter(fpg => fpg.datos.cliente == 1 && fpg.lapos_integrado == 1).length > 0) {
        this.$swal.fire({
          icon: 'warning',
          title: titulo,
          text: 'Existen formas de pago marcadas como Cliente y no se ingresó Datos del Cliente en la seccion "Datos Cliente"'
        })
        return
      }

      // controlo que ingresen la terminal siempre y cuando el local este marcado como lapos integrado y como que ingresa una terminal para el cobro
      if (this.ptovta.fp_integrado == 1 && this.ptovta.ingresa_terminal == 1 &&
          this.formas_pago_group.filter(fpg => fpg.lapos_integrado == 1).length > 0 && !this.terminal) {
        this.$swal.fire({
          icon: 'warning',
          title: titulo,
          text: 'Debe seleccionar la terminal integrada en la que se van a realizar los cobros'
        })
        return
      }
      
      // controlamos que las formas de pago no se esten cobrando como lapos integrado cuando la configuracion de la tarjeta no lo admita
      if (this.ptovta.fp_integrado == 1) {
        let inconsistentes = this.formas_pago_group.filter(fpg => fpg.tar_lapos_int == 0 && fpg.lapos_integrado == 1)
        if (inconsistentes.length > 0) {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: `Las financiaciones ${inconsistentes.map(inc => inc.descripcion).join(', ')} no se pueden cobrar con Lapos Integrado`
          })
          return
        }
      }

      // si están vendiendo una Gift Card el cliente es obligatorio
      if (cliente_valido && this.articulos.filter(art => art.gift_card == 1).length > 0) {
        this.$swal.fire({
          icon: 'warning',
          title: titulo,
          text: 'Se está vendiendo una Gift Card, debe ingresar los datos del Cliente'
        })
        return
      }

      // pasó todas las validaciones >:D
      const articulos = this.articulos.map(art => {
        return {
          codigo: art.codigo,
          nombre: art.nombre,
          precio: art.precio,
          precio_lista: art.precio_lista,
          importe: art.importe,
          solicita_serie: art.solicita_serie,
          solicita_datos: art.solicita_datos,
          cantidad: art.cantidad,
          descuento: art.descuento,
          gift_card: art.gift_card,
          importe_gift: art.importe_gift,
          combo: art.combo,
          info_adicional: art.info_adicional,
          series: art.artseries
        }
      })

      // armo mi array de financiaciones desagrupadas por articulos
      // voy a trabajar sobre una copia del array de fp x art para no modificar los importes reales
      let array_fp = []
      let array_copy = JSON.parse(JSON.stringify(this.financiaciones_especiales_articulos))

      for (const grupo of this.formas_pago_group) {
        // busco las fp del grupo por id interno
        let forma_pago = this.formas_pago.filter(fp => fp.id_interno == grupo.id_interno)
        let total_disponible = JSON.stringify(grupo.monto)

        for (const fp of forma_pago) {
          // por cada fp q encunetro reviso si se encuentra en especiales x articulo
          let articulos = array_copy.filter(fea => fea.forma_pago_id == fp.forma_pago_id)
          let monto_utilizado = 0

          // si no encontro articulo es pq es una fp comunacha
          if (articulos.length == 0) {
            // inserto el monto total de la fp sin articulo
            monto_utilizado = parseFloat(fp.monto)
            array_fp.push({
              articulo_codigo: null,
              id_interno: fp.id_interno,
              forma_pago_id: fp.forma_pago_id,
              fp_cod_id: fp.fp_cod_id,
              cuota: fp.cuota,
              porcentaje: fp.porcentaje,
              monto: monto_utilizado
            })
          } else {
            // si encontro al menos 1 articulo es una fp especial
            // por cada articulo q encuentro le imputo un monto correspondiente hasta q abarque el monto total de la fp agrupada mientras el art tenga monto disponible
            for (let articulo of articulos) {
              if (total_disponible > 0 && articulo.importe_disponible > 0) {
                // si el monto total de la fp supera lo disponible del art => agrego lo disponible del art, sino agrego el total disponible de la fp
                monto_utilizado = total_disponible > articulo.importe_disponible ? parseFloat(articulo.importe_disponible) : parseFloat(total_disponible)
                articulo.importe_disponible -= monto_utilizado
                
                array_fp.push({
                  articulo_codigo: articulo.articulo_codigo,
                  id_interno: fp.id_interno,
                  forma_pago_id: fp.forma_pago_id,
                  fp_cod_id: fp.fp_cod_id,
                  cuota: fp.cuota,
                  porcentaje: fp.porcentaje,
                  monto: monto_utilizado
                })
              }
            }
          }
          total_disponible -= monto_utilizado
        }
      }

      // como mi array_fp y formas_pago deberian ser iguales en longitud y monto (en teoria si salio todo bien)
      // directamente le agrego el codigo de articulo al de formas_pago
      let formas_pago = []
      for (const fp of this.formas_pago) {
        let coincidencia = array_fp.filter(afp => afp.id_interno == fp.id_interno && afp.forma_pago_id == fp.forma_pago_id &&
                                                  afp.fp_cod_id == fp.fp_cod_id && afp.cuota == fp.cuota && afp.porcentaje == fp.porcentaje &&
                                                  roundNumberTF(afp.monto) == roundNumberTF(fp.monto))
        if (coincidencia.length == 1) {
          let forma_pago = JSON.parse(JSON.stringify(fp))
          forma_pago.articulo_codigo = coincidencia[0].articulo_codigo
          formas_pago.push(forma_pago)
        } else {
          this.$swal.fire({
            icon: 'warning',
            title: titulo,
            text: 'Se detectaron inconsistencias al agrupar las financiacines especiales por artículo'
          })
          return
        }
      }

      // guardo en localStorage el nro de terminal por default
      if (this.terminal) localStorage.setItem('terminal_def', JSON.stringify(this.terminal))

      this.$swal.fire({
        icon: 'success',
        title: 'Simulación exitosa'
      })
    },
    /****************
      FINANCIACIONES
     ****************/
    setSelectedCheck (item, opcion) {
      // restablezco los valores
      item.datos = JSON.parse(JSON.stringify(this.datosFPLimpio))
      item.completa = false

      if (opcion == 1) {
        // es cobro electronico
        // queda tildado cobro electronico?
        if (item.cobro_online == 1) {
          item.estado_solicitud = 1
          item.lapos_integrado = 0
          item.qr = 0
        } else {
          // destildaron, pero quedó tildado el de lapos integrado?
          if (item.lapos_integrado == 0) {
            item.estado_solicitud = 0
            // me fijo si tengo que devolverle el tilde a lapos integrado
            if (this.ptovta.fp_integrado == 1) {
              item.lapos_integrado = 1
              this.setSelectedCheck(item, 2)
              return
            }
          }
        }
      } else {
        // es Lapos integrado
        // queda tildado lapos integrado?
        if (item.lapos_integrado == 1) {
          item.cobro_online = 0
          item.estado_solicitud = 1
        } else {
          // destildo qr
          item.qr = 0
          // destildaron, pero quedó tildado el de cobro online?
          if (item.cobro_online == 0) {
            // destildaron los dos check, reseteo el ingreso de tarjeta normal
            item.estado_solicitud = 0
          }
        }
      }
    },
    async agregarFinanciacion (financiacion, monto) {
      // busco la financiacion comunacha para obtener los datos extra
      let forma_pago = this.financiaciones_disponibles.filter(fin =>
        fin.fp_cod_id == financiacion.fp_cod_id &&
        fin.fc_cod_id == financiacion.fc_cod_id &&
        fin.cuota_interna == financiacion.cuota_interna &&
        fin.tarjeta_codigo == financiacion.tarjeta_codigo
      )
      if (forma_pago.length == 0) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Ocurrio un error inesperado al agregar la forma de pago',
          color: 'error'
        })
      }
      
      this.formas_pago.push({
        id_interno: this.id_fin,
        forma_pago_nombre: financiacion.forma_pago_nombre,
        tarjeta_nombre: financiacion.tarjeta_nombre,
        cuota_nombre: financiacion.cuota_nombre,
        forma_pago_id: financiacion.forma_pago_id,
        fp_cod_id: financiacion.fp_cod_id,
        fc_cod_id: financiacion.fc_cod_id,
        tarjeta_codigo: financiacion.tarjeta_codigo,
        cuota_interna: financiacion.cuota_interna,
        cuota: financiacion.cuota,
        porcentaje: financiacion.porcentaje,
        monto: monto
      })

      // agrupo las fp por id interno
      let group_by = this.formas_pago.reduce(function (r, a) {
        r[a.id_interno] = r[a.id_interno] || []
        r[a.id_interno].push(a)
        return r
      }, Object.create(null))

      // el grupo que me interesa es el que tenga el mismo id_interno que mi id_fin actual
      let group = group_by[this.id_fin]
      let importe = 0
      let importe_final = 0

      // calculo el importe total en base a las alicuotas
      for (const index in group) {
        const item = group[index]
        importe += parseFloat(item.monto)
        importe_final += parseFloat(item.monto) * (1 + (parseFloat(item.porcentaje) / 100))
      }

      // verifico si en las fp agrupadas ya existe una con mi id interno
      let existe = this.formas_pago_group.find(fpg => fpg.id_interno == this.id_fin)

      // si existe solo le actualizo los importes
      if (existe) {
        existe.monto = importe
        existe.total = importe_final
        existe.monto_cuotas = importe_final / parseInt(group[0].cuota)
      } else {
        this.formas_pago_group.push({
          id: null,
          id_interno: this.id_fin,
          id_solicitud: null,
          descripcion: forma_pago[0].solicita_banco == 1 || group[0].tarjeta_nombre == group[0].cuota_nombre ? group[0].forma_pago_nombre : group[0].tarjeta_nombre + ' - ' + group[0].cuota_nombre,
          forma_pago_cod_id: group[0].fp_cod_id,
          forma_cobro_cod_id: group[0].fc_cod_id,
          tarjeta_codigo: group[0].tarjeta_codigo,
          banco: null,
          forma_pago_viejo_id: forma_pago[0].forma_pago_viejo_id,
          solicita_tarjeta: forma_pago[0].tarjeta,
          cod_autoriz: forma_pago[0].cod_autoriz,
          cuotas: forma_pago[0].cuotas,
          imei_cotizado: forma_pago[0].imei_cotizado,
          monto: importe,
          total: importe_final,
          monto_cuotas: importe_final / parseInt(group[0].cuota),
          cant_cuotas: parseInt(group[0].cuota),
          cupon_lote: forma_pago[0].cupon_lote,
          solicita_banco: forma_pago[0].solicita_banco,
          cantidad_digitos: forma_pago[0].cantidad_digitos,
          cuota_interna: group[0].cuota_interna,
          tar_lapos_int: forma_pago[0].tar_lapos_int,
          estado_solicitud: 0,
          cobro_online: 0,
          error_id: 0,
          qr: 0,
          decidir: this.ptovta.decidir,
          lapos_integrado: this.ptovta.fp_integrado == 1 && forma_pago[0].tar_lapos_int == 1 ? 1 : 0,
          cobro_transaccion: '',
          decidir_reprocesar: 1,
          completa: false,
          datos: this.datosFPLimpio
        })
      }
      this.getDescuentos()
    },
    async agregarFinEsp (financiacion_especial, simulacion) {
      if (simulacion != 1) this.id_fin++

      let monto_ingresado = parseFloat(this.monto)
      let importe_final = 0

      for (const fin_esp of financiacion_especial) {
        // si ya no tengo nada mas que agregar entonces quiebro
        if (monto_ingresado == 0) break

        // reviso si el monto que ingresó es mayor o menor que el disponible (importe total de la fin esp - suma de todas esas fin esp en las fp)
        let sumatoria = this.formas_pago.filter(fp => fp.forma_pago_id == fin_esp.forma_pago_id).reduce((sum, fp) => sum + fp.monto, 0)
        let monto_disponible = parseFloat(fin_esp.importe_total) - parseFloat(sumatoria)

        // si el monto disponible es negativo => es 0
        if (monto_disponible < 0) monto_disponible = 0

        // si el monto ingresado supera el saldo disponible de la financiacion especial => agrego lo disponible
        if (roundNumberTF(monto_disponible) < roundNumberTF(monto_ingresado)) {
          if (monto_disponible > 0) {
            if (simulacion == 1) {
              let importe_especial = monto_disponible * (1 + (parseFloat(fin_esp.porcentaje) / 100))
              importe_final += importe_especial
              this.simulacion.push({
                nombre: fin_esp.forma_pago_nombre,
                importe: importe_especial,
                alicuota: fin_esp.porcentaje
              })
            } else {
              this.agregarFinanciacion(fin_esp, monto_disponible)
            }
            monto_ingresado -= monto_disponible 
          }
        }
        // si el monto esta dentro del margen del saldo de la especial => agrego todo
        else {
          if (simulacion == 1) {
            let importe_especial = monto_ingresado * (1 + (parseFloat(fin_esp.porcentaje) / 100))
            importe_final += importe_especial
            this.simulacion.push({
              nombre: fin_esp.forma_pago_nombre,
              importe: importe_especial,
              alicuota: fin_esp.porcentaje
            })
          } else {
            this.agregarFinanciacion(fin_esp, monto_ingresado)
          }
          monto_ingresado = 0
        }
      }

      // si ya termine de ciclar y todavia me queda parte del monto ingresado => lo agrego a la comunacha
      if (monto_ingresado > 0) {
        if (simulacion == 1) {
          let importe_comunacho = monto_ingresado * (1 + (parseFloat(this.forma_pago.porcentaje) / 100))
          importe_final += importe_comunacho
          this.simulacion.push({
            nombre: this.forma_pago.forma_pago_nombre,
            importe: importe_comunacho,
            alicuota: this.forma_pago.porcentaje
          })
        } else {
          this.agregarFinanciacion(this.forma_pago, monto_ingresado)
        }
      }

      // asigno los improtes
      this.fp_importe = importe_final
      this.monto_cuotas = parseFloat(this.fp_importe) / parseInt(this.forma_pago.cuota)
    },
    async calcularFinanciacion (simulacion) {
      this.simulacion = []

      // obtengo los datos de la financiacion seleccionada
      const pago_id = this.forma_pago.fp_cod_id
      const cobro_id = this.forma_pago.fc_cod_id
      const cuota_interna = this.forma_pago.cuota_interna
      const tarjeta_id = this.forma_pago.tarjeta_codigo

      // busco en las financiaciones especiales
      let financiaciones_especiales = this.financiaciones_especiales.filter(fin =>
        fin.fp_cod_id == pago_id &&
        fin.fc_cod_id == cobro_id &&
        fin.cuota_interna == cuota_interna &&
        fin.tarjeta_codigo == tarjeta_id
      )

      if (financiaciones_especiales.length > 0) {
        // si encontro una o mas, ordeno el array por alicuota desc
        order_list_by(financiaciones_especiales, 'porcentaje')
        this.agregarFinEsp(financiaciones_especiales, simulacion)
        if (simulacion != 1) this.limpiarFP(1)
      } else {
        // si no encontró ninguna, agrego la q selecciono
        if (simulacion == 1) {
          this.fp_importe = parseFloat(this.monto) * (1 + (parseFloat(this.forma_pago.porcentaje) / 100))
          this.monto_cuotas = parseFloat(this.fp_importe) / parseInt(this.forma_pago.cuota)
          this.simulacion.push({
            nombre: this.forma_pago.forma_pago_nombre,
            importe: this.fp_importe,
            alicuota: this.forma_pago.porcentaje
          })
        } else {
          this.id_fin++
          this.agregarFinanciacion(this.forma_pago, this.monto)
          this.limpiarFP(1)
        }
      }
    },
    async simularFormaPago () {
      if (this.monto == null || parseFloat(this.monto) <= 0) {
        this.forma_pago = {}
        this.fp_importe = 0
        this.monto_cuotas = 0
        this.bancarizada = 0
        this.banco = null
        return
      }
      if (this.forma_pago == null || Object.keys(this.forma_pago) == 0) {
        this.fp_importe = 0
        this.monto_cuotas = 0
        this.bancarizada = 0
        this.banco = null
        return
      }
      this.bancarizada = this.forma_pago.bancarizada
      this.calcularFinanciacion(1)
    },
    async agreagrFormaPago () {
      // validaciones
      if (this.monto == null || parseFloat(this.monto) <= 0) {
        return this.$store.dispatch('show_snackbar', {
          text: 'El monto debe ser mayor a 0',
          color: 'orange'
        })
      }
      if (roundNumberTF(this.monto_articulos) < roundNumberTF(this.monto) ||
          roundNumberTF(this.monto_articulos) < roundNumberTF(this.subtotal_fp + parseFloat(this.monto))) {
        return this.$store.dispatch('show_snackbar', {
          text: 'El monto ingresado supera el subtotal de la venta',
          color: 'orange'
        })
      }
      if (Object.keys(this.forma_pago).length == 0) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Seleccione la forma de pago',
          color: 'orange'
        })
      }
      if (this.forma_pago.bancarizada == 1 && this.banco == null) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Seleccione el banco de la tarjeta',
          color: 'orange'
        })
      }
      this.calcularFinanciacion()
    },
    preseleccionarFP (item) {
      if (/*(this.monto == null || parseFloat(this.monto) <= 0) && */item != null && Object.keys(item).length != 0) {
        let forma_pago = this.financiaciones_disponibles.filter(fin =>
          fin.fp_cod_id == item.fp_cod_id &&
          fin.fc_cod_id == item.fc_cod_id &&
          fin.cuota_interna == item.cuota_interna &&
          fin.tarjeta_codigo == item.tarjeta_codigo
        )
        if (forma_pago.length == 1) {
          this.forma_pago = forma_pago[0]
        }
        this.monto = this.monto_articulos - this.subtotal_fp
        this.calcularFinanciacion(1)
      }
    },
    armarArrayFinanciacionEspecial () {
      if (this.formas_pago_group.length > 0) {
        this.$store.dispatch('show_snackbar', {
          text: 'Se quitaron las Formas de Pago agregadas debido a que cambiaron algunos importes de los artículos. Verificar',
          color: 'deep-orange',
          time: 4000
        })
      }
      this.formas_pago = []
      this.formas_pago_group = []
      this.financiaciones_especiales = []
      this.financiaciones_especiales_articulos = []
      for (const articulo of this.articulos) {
        if (articulo.financiacion == null || Object.keys(articulo.financiacion).length == 0) {
          for (const art_fin of articulo.financiaciones) {
            const financiacion = art_fin.financiacion
            this.financiaciones_especiales_articulos.push({
              forma_pago_nombre: financiacion.forma_pago_nombre,
              tarjeta_nombre: financiacion.tarjeta_nombre,
              cuota_nombre: financiacion.cuota_nombre,
              forma_pago_id: financiacion.forma_pago_id,
              fp_cod_id: financiacion.fp_cod_id,
              fc_cod_id: financiacion.fc_cod_id,
              tarjeta_codigo: financiacion.tarjeta_codigo,
              cuota_id: financiacion.cuota_id,
              cuota: financiacion.cuota,
              cuota_interna: financiacion.cuota_interna,
              porcentaje: financiacion.porcentaje,
              articulo_codigo: articulo.codigo,
              importe_disponible: art_fin.monto,
              importe_total: art_fin.monto
            })
          }
        } else {
          const financiacion = articulo.financiacion
          this.financiaciones_especiales_articulos.push({
            forma_pago_nombre: financiacion.forma_pago_nombre,
            tarjeta_nombre: financiacion.tarjeta_nombre,
            cuota_nombre: financiacion.cuota_nombre,
            forma_pago_id: financiacion.forma_pago_id,
            fp_cod_id: financiacion.fp_cod_id,
            fc_cod_id: financiacion.fc_cod_id,
            tarjeta_codigo: financiacion.tarjeta_codigo,
            cuota_id: financiacion.cuota_id,
            cuota: financiacion.cuota,
            cuota_interna: financiacion.cuota_interna,
            porcentaje: financiacion.porcentaje,
            articulo_codigo: articulo.codigo,
            importe_disponible: articulo.importe,
            importe_total: articulo.importe
          })
        }
      }

      let financiaciones_especiales = []
      // agrupo las financiaciones especiales
      let group_by_fe = this.financiaciones_especiales_articulos.reduce(function (r, a) {
        r[a.forma_pago_id] = r[a.forma_pago_id] || []
        r[a.forma_pago_id].push(a)
        return r
      }, Object.create(null))

      // ciclo for por cada grupo de financiaciones
      for (const key in group_by_fe) {
        let group = group_by_fe[key]
        let monto_total = 0

        // calculo el monto total disponible para las mismas financiaciones
        for (const index in group) {
          const item = group[index]
          monto_total += parseFloat(item.importe_total)
        }

        financiaciones_especiales.push({
          forma_pago_nombre: group[0].forma_pago_nombre,
          tarjeta_nombre: group[0].tarjeta_nombre,
          cuota_nombre: group[0].cuota_nombre,
          forma_pago_id: key,
          fp_cod_id: group[0].fp_cod_id,
          fc_cod_id: group[0].fc_cod_id,
          tarjeta_codigo: group[0].tarjeta_codigo,
          cuota_id: group[0].cuota_id,
          cuota: group[0].cuota,
          cuota_interna: group[0].cuota_interna,
          porcentaje: group[0].porcentaje,
          importe_total: monto_total
        })
      }
      this.financiaciones_especiales = financiaciones_especiales
    },
    calcularCuotasFinEsp (item) {
      if (Object.keys(item.financiacion).length != 0) {
        // obtengo el articulo
        let articulo = this.articulos.find(art => art.codigo == item.art_cod)
        let total = 0
        // sumo las financiaciones especiales del articulo
        articulo.financiaciones.forEach(fin => {
          total += parseFloat(fin.monto)
        })
        // validaciones
        if (item.monto == null || roundNumberTF(item.monto) <= 0) item.monto = 0
        if (roundNumberTF(total) > roundNumberTF(articulo.importe)) {
          item.monto = 0
          item.cuota = 0
          item.total = 0
          return this.$store.dispatch('show_snackbar', {
            text: 'El monto ingresado supera el importe del artículo',
            color: 'orange'
          })
        }
        // calculo
        const alicuota = 1 + (parseFloat(item.financiacion.porcentaje) / 100)
        item.total = alicuota * parseFloat(item.monto)
        item.cuota = item.total / parseInt(item.financiacion.cuota)
      }
      this.armarPreseleccionado(item.financiacion)
    },
    armarPreseleccionado (financiacion) {
      this.armarArrayFinanciacionEspecial()
      this.preseleccionarFP(financiacion)
    },
    desglosarFinEsp (item) {
      let unaFinanciacion = {
        art_cod: item.codigo,
        financiacion: item.financiacion ? item.financiacion : {},
        financiaciones_especiales: item.financiaciones_especiales,
        eliminado: 0,
        monto: 0,
        cuota: 0,
        total: 0
      }
      // si no agrego desgloso especiales aun, heredo la fin. unitaria
      if (this.expanded.indexOf(item) == -1) {
        this.expanded.push(item)
        item.financiacion = {}
        item.financiaciones.push(unaFinanciacion)
      } else {
        // valido si todas las financiaciones tienen monto y financiacion
        let incompletas = item.financiaciones.filter(fin => parseFloat(fin.monto) == 0 || Object.keys(fin.financiacion).length == 0)
        if (incompletas.length == 0) {
          // valido que todavia pueda ingresar financiaciones
          if (item.financiaciones.length >= item.financiaciones_especiales.length) {
            return this.$store.dispatch('show_snackbar', {
              text: 'Ya agregó todas las financiaciones disponibles para este producto',
              color: 'orange'
            })
          }
          item.financiaciones.push(unaFinanciacion)
        } else {
          this.$store.dispatch('show_snackbar', {
            text: 'Complete las financiaciones para agregar nuevas',
            color: 'orange'
          })
        }
      }
      this.armarArrayFinanciacionEspecial()
    },
    quitarFinEsp (item) {
      item.eliminado = 1
      // obtengo el articulo
      let articulo = this.articulos.find(art => art.codigo == item.art_cod)
      // elimino todas las fin esp. del articulo que tengan el eliminado
      articulo.financiaciones = articulo.financiaciones.filter(fin => fin.eliminado != 1)
      // si era la ultima fin esp. quito el articulo de expandidos y seteo nuevamente la fin esp. unitaria
      if (articulo.financiaciones.length == 0) {
        articulo.financiacion = {}
        let expand = this.expanded.find(exp => exp.codigo == articulo.codigo)
        if (expand) this.expanded.splice(this.expanded.indexOf(expand), 1)
      }
      this.armarArrayFinanciacionEspecial()
    },
    quitarFP (item) {
      const index = this.formas_pago_group.indexOf(item)
      this.formas_pago = this.formas_pago.filter(fp => fp.id_interno != item.id_interno)
      this.formas_pago_group.splice(index, 1)
      this.getDescuentos()
    },
    async limpiarFP (verificar_siguiente) {
      this.monto = null
      this.forma_pago = {}
      this.fp_importe = 0
      this.monto_cuotas = 0
      this.bancarizada = 0
      this.banco = null

      if (verificar_siguiente == 1) {
        // preselecciono la siguiente especial (en caso de existir y q le falte completar el total)
        if (roundNumberTF(this.monto_articulos) > roundNumberTF(this.subtotal_fp)) {
          let sobran = this.financiaciones_especiales.map(fe => fe.forma_pago_id).filter(x => !this.formas_pago.map(fp => fp.forma_pago_id).includes(x))
          if (sobran.length > 0) {
            let especial = this.financiaciones_especiales.find(fe => fe.forma_pago_id == sobran[0])
            await new Promise(resolve => setTimeout(resolve, 1))
            this.preseleccionarFP(especial)
          }
        }
      }
    },
    /***********
      ARTICULOS
     ***********/
    async buscarArticulo (buscar) {
      if (this.articulo == null || this.articulo == '' || this.$store.state.loading) return

      let art_cod = this.articulo
      let serie = null
      
      if (buscar == 1) {
        if (Object.keys(this.ptovta).length == 0) {
          this.$store.dispatch('show_snackbar', {
            text: 'Seleccione un local para agregar artículos',
            color: 'info'
          })
          return
        }
        this.$store.state.loading = true
        let resultado = await this.$store.dispatch('articulos/get_articulo', {
          codigo: this.articulo,
          local: this.ptovta.local_accesorios
        })
        this.$store.state.loading = false

        if (resultado.exito == 1) {
          if (resultado.data.length == 1) {
            art_cod = resultado.data[0].codigo
            serie = resultado.data[0].serie
          } else if (resultado.data.length > 1) {
            this.select_arts = resultado.data
            this.modal_select_art = true
            this.articulo = null
            return
          }
        } else {
          this.$store.dispatch('show_snackbar', {
            text: resultado.message,
            color: 'error'
          })
          return
        }
        this.$refs.vtfArticulo.focus()
      }

      let articulo = this.precios.find(art => art.codigo == art_cod)
      if (articulo) {
        let existe = this.articulos.find(art => art.codigo == articulo.codigo)
        if (existe) {
          // si solicita serie y buscó por serie, verifico que la serie a ingresar no este ya incluida
          if (existe.solicita_serie == 1 && serie != null) {
            // si no está, la agrego
            if (existe.series.filter(se => se.serie == serie).length == 0) {
              existe.series.push({ serie: serie })
            } else {
              this.articulo = null
              this.$store.dispatch('show_snackbar', {
                text: 'La serie ingresada ya se ecuentra en el listado',
                color: 'info'
              })
              return
            }
          }
          // si no buscó por serie => muestro el modal para q seleccione la serie
          else if (existe.solicita_serie == 1) {
            this.solicitarSerie(existe)
            this.articulo = null
            return
          }
          existe.cantidad ++
          this.recalcularImporteArticulo(existe)
          this.articulo = null
          return
        }
        let unArticulo = {
          codigo: articulo.codigo,
          nombre: articulo.nombre,
          precio: articulo.precio,
          precio_lista: articulo.precio,
          importe: articulo.precio,
          solicita_serie: articulo.series,
          solicita_datos: articulo.cli_datos_add,
          cantidad: 1,
          descuento: 0,
          gift_card: 0,
          importe_gift: 0,
          combo: null,
          info_adicional: '',
          financiaciones: [],
          series_disponibles: [],
          series: []
        }

        // busco financiaciones
        this.$store.state.loading = true
        await this.$store.dispatch('financiaciones/get_financiaciones_condcom', {
          articulo: articulo.codigo,
          especial: 1,
          empresas: [{codigo: this.ptovta.empresa}],
          locales: [{local_codigo: this.ptovta.id}],
          sucursales: [{sucursal_codigo: this.sucursal}],
          canales: [{canal_id: this.ptovta.canal}]
        })
          .then((res) => {
            unArticulo.financiaciones_especiales = res.formas_pago_habilitadas
          })
          .catch(error => {
            this.$store.state.loading = false
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
            return
          })
        this.$store.state.loading = false
        // si es un art que solicita serie, abro el modal
        if (unArticulo.solicita_serie == 1) {
          this.solicitarSerie(unArticulo, serie)
          this.articulo = null
          return
        }
        this.articulos.unshift(unArticulo)
        this.getDescuentos(1)
      }
      else {
        this.$store.dispatch('show_snackbar', {
          text: 'No se encontro el artículo ' + this.articulo,
          color: 'error'
        })
      }
      this.articulo = null
    },
    async solicitarSerie (articulo, serie) {
      if (articulo.series_disponibles.length == 0) {
        this.$store.state.loading = true
        let result = await this.$store.dispatch('articulos/get_series', {
          articulo: articulo.codigo,
          local: this.ptovta.local_accesorios
        })
        this.$store.state.loading = false

        if (result.exito == 1) {
          articulo.series_disponibles = result.data
          // si buscó por serie y esa serie existe => directamente la agrego
          if (serie && articulo.series_disponibles.filter(se => se.serie == serie).length != 0) {
            articulo.series.push({ serie: serie })
            this.articulos.unshift(articulo)
            this.getDescuentos(1)
            return
          }
          this.articulo_select = articulo
          this.modal_series = true
        } else {
          this.$store.dispatch('show_snackbar', {
            text: result.message,
            color: 'error'
          })
        }
      } else {
        this.articulo_select = articulo
        this.modal_series = true
      }
    },
    validarCantidades (art) {
      if (art.solicita_serie == 1) {
        const cantidad = parseInt(art.cantidad)
        if (art.cantidad == art.series.length) return

        if (cantidad <= 0) {
          art.cantidad = art.series.length
        } else if (cantidad > art.series_disponibles.length) {
          art.cantidad = art.series.length
          this.$store.dispatch('show_snackbar', {
            text: 'La cantidad ingresada supera el stock disponible del artículo',
            color: 'error'
          })
        } else {
          art.cantidad = cantidad
          this.articulo_select = art
          this.modal_series = true
        }
      } else {
        this.recalcularImporteArticulo(art)
      }
    },
    cancelarSeries () {
      let existe = this.articulos.find(art => art.codigo == this.articulo_select.codigo)
      if (existe) {
        existe.cantidad = this.articulo_select.series.length
      }
      this.articulo_select = {}
      this.modal_series = false
      this.cantOriginal = 1
    },
    setSeries (series, cantidad) {
      let existe = this.articulos.find(art => art.codigo == this.articulo_select.codigo)
      if (existe) {
        existe.series = series
        existe.cantidad = cantidad
        this.recalcularImporteArticulo(existe)
      } else {
        this.articulo_select.series = series
        this.articulo_select.cantidad = cantidad
        this.articulos.unshift(this.articulo_select)
        this.recalcularImporteArticulo(this.articulo_select)
      }
      this.articulo_select = {}
      this.modal_series = false
    },
    seleccionarArticulo (art) {
      this.modal_select_art = false
      this.articulo = art.codigo
      this.buscarArticulo()
    },
    quitarArticulo (item) {
      const index = this.articulos.indexOf(item)
      this.articulos.splice(index, 1)
      // si esta expandido tmb lo elimino de ahi
      let expand = this.expanded.find(exp => exp.codigo == item.codigo)
      if (expand) this.expanded.splice(this.expanded.indexOf(expand), 1)
      this.getDescuentos(1)
    },
    recalcularImporteArticulo (articulo, no_calcular_descuentos) {
      // borro todas las financiaciones para ese articulo
      let expand = this.expanded.find(exp => exp.codigo == articulo.codigo)
      if (expand) this.expanded.splice(this.expanded.indexOf(expand), 1)

      articulo.financiaciones = []
    
      // recalculo el importe
      if (articulo.cantidad == '' || parseInt(articulo.cantidad) <= 0) articulo.cantidad = 1
      if (articulo.precio == null || roundNumberTF(articulo.precio) <= 0) articulo.precio = articulo.precio_lista
      articulo.importe = parseInt(articulo.cantidad) * parseFloat(articulo.precio)

      if (no_calcular_descuentos == 1) {
        this.armarArrayFinanciacionEspecial()
        this.validarGF()
      } else {
        this.getDescuentos(1)
      }
    },
    calcularTotales () {
      this.subtotal_fp = parseFloat(this.formas_pago.reduce((sum, fp) => sum + fp.monto, 0))
      this.monto_fp = parseFloat(this.formas_pago_group.reduce((sum, fpg) => sum + fpg.monto, 0))
      this.monto_fp_alic = parseFloat(this.formas_pago_group.reduce((sum, fpa) => sum + fpa.total, 0))
      this.monto_articulos = parseFloat(this.articulos.reduce((sum, art) => sum + art.importe, 0))
      this.monto_efectivo = roundNumberTF(this.monto_articulos - this.monto_fp)
    },
    setInfoAdicional () {
      this.articulo_select.info_adicional = JSON.parse(JSON.stringify(this.info_adicional))
      this.modal_info_adicional = false
    },
    /*********
      GIFT CARD
     *********/
    async validarGF () {
      if (!this.giftcard_codigo) {
        this.aplicarGF(0)
        return
      }

      this.$store.state.loading = true
      let result = await this.$store.dispatch('ventas/validar_gf', this.giftcard_codigo)
      this.$store.state.loading = false

      if (result.exito == 1) {
        switch (result.size) {
          case 1:
            const datos = result.data[0]
            // si la gf tiene dni, si o si tiene q ingresar el dni en clientes
            if (datos.dni) {
              if (!this.cliente.num_doc) {
                this.limpiarGF()
                this.$swal.fire({
                  icon: 'info',
                  title: 'Ingrese los datos del cliente e intente nuevamente'
                })
              } else if (this.cliente.num_doc == datos.dni) {
                this.aplicarGF(datos.importe)
              } else {
                this.limpiarGF()
                this.$swal.fire({
                  icon: 'info',
                  title: 'Los datos del Cliente no coinciden con los del destinatario de la Gift Card'
                })
              }
            } else {
              this.aplicarGF(datos.importe)
            }
            break
          case 0:
            this.limpiarGF()
            this.$swal.fire({
              icon: 'info',
              title: 'No se encontraron datos de la Gift Card indicada'
            })
            break
          default:
            this.limpiarGF()
            this.$swal.fire({
              icon: 'info',
              title: 'Se encontraron muchos datos de la Gift Card indicada'
            })
            break
        }
      } else {
        this.limpiarGF()
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async newGF (item) {
      // verifico si ya existe una gf en el detalle
      let gift = this.articulos.filter(art => art.gift_card == 1)

      if (gift.length == 0) {
        // verifico si hace falta traer la info del articulo
        if (this.stock_giftcard.length == 0) {
          this.$store.state.loading = true
          let result = await this.$store.dispatch('ventas/get_stock_gf')
          this.$store.state.loading = false

          if (result.exito == 1) {
            switch (result.size) {
              case 0:
                this.$store.dispatch('show_snackbar', {
                  text: 'No se encontró stock de Gift Card',
                  color: 'error'
                })
                return
              case 1:
                this.stock_giftcard = result.data
                break
              default:
                this.$store.dispatch('show_snackbar', {
                  text: 'Se encontró mucho stock de Gift Card',
                  color: 'error'
                })
                return
            }
          } else {
            this.$store.dispatch('show_snackbar', {
              text: result.message,
              color: 'error'
            })
            return
          }
        }

        const giftcard = this.stock_giftcard[0]

        this.articulos.unshift({
          codigo: giftcard.codigo,
          nombre: giftcard.nombre,
          precio: item.precio,
          precio_lista: item.precio,
          importe: item.precio,
          titular: item.nombre,
          dni: item.dni,
          solicita_serie: giftcard.series,
          solicita_datos: giftcard.cli_datos_add,
          cantidad: 1,
          descuento: 0,
          gift_card: 1,
          importe_gift: 0,
          combo: null,
          info_adicional: '',
          financiaciones: [],
          series_disponibles: [],
          financiaciones_especiales: [],
          series: []
        })
        this.modal_gf = false
        this.getDescuentos(1)
      } else {
        this.modal_gf = false
        this.$store.dispatch('show_snackbar', {
          text: 'Ya ingresó una Gift Card al detalle de artículos',
          color: 'error'
        })
      }
    },
    updateGF (item) {
      // busco la gf
      let giftcard = this.articulos.find(art => art.gift_card == 1)
      if (giftcard) {
        const precio_viejo = JSON.parse(JSON.stringify(giftcard.importe))
        giftcard.precio = item.precio
        giftcard.precio_lista = item.precio
        giftcard.importe = item.precio
        giftcard.titular = item.nombre
        giftcard.dni = item.dni

        // solo si se actualizo el precio vuelvo a calcular los descuentos
        if (roundNumberTF(precio_viejo) != roundNumberTF(item.precio)) {
          this.getDescuentos(1)          
        }
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'No se encontró la Gift Card en el detalle de artículos',
          color: 'error'
        })
      }
    },
    aplicarGF (monto) {
      let saldo_restante = parseFloat(monto)
      for (let articulo of this.articulos) {
        const precio = parseFloat(articulo.precio) * parseInt(articulo.cantidad)
        if (roundNumberTF(saldo_restante) == 0) {
          articulo.importe_gift = 0
          articulo.importe = precio
        } else if (roundNumberTF(precio) < roundNumberTF(saldo_restante)) {
          articulo.importe_gift = precio
          articulo.importe = 0
          saldo_restante -= precio
        } else {
          articulo.importe_gift = saldo_restante
          articulo.importe = precio - saldo_restante
          saldo_restante = 0
        }
      }
    },
    openGF (gf) {
      if (Object.keys(this.ptovta).length == 0) {
        this.$store.dispatch('show_snackbar', {
          text: 'Seleccione un local para agregar una Gift Card',
          color: 'info'
        })
        return
      }
      this.art_gf = gf
      this.modal_gf = true
    },
    limpiarGF () {
      this.giftcard_codigo = ''
      this.aplicarGF(0)
    },
    /*********
      GETTERS
     *********/
    async getDescuentos (limpiar_fp, documento) {
      if (this.ptovta == null || Object.keys(this.ptovta).length == 0) return

      const efectivo = this.efectivo || this.debito ? 1 : 0
      const cliente = {
        cuit: this.cliente.cuit,
        codigo: this.cliente.codigo,
        num_doc: !this.cliente.num_doc && !this.cliente.cui ? documento : this.cliente.num_doc,
        tipo_doc: this.cliente.tipo_doc
      }
      const articulos = this.articulos.map(art => {
        return {
          codigo: art.codigo,
          cantidad: art.cantidad
        }
      })
      const formas_pago = this.formas_pago.map(fp => {
        return {
          fp_id: fp.fp_cod_id,
          cuota: fp.cuota
        }
      })

      this.empleado = 0
      this.lista_desc = null
      this.promociones = []

      this.$store.state.loading_dct = true
      let result = await this.$store.dispatch('ventas/calcular_descuento', {
        cliente: this.giftcard ? {} : cliente,
        articulos: articulos,
        formas_pago: this.giftcard ? [] : formas_pago,
        local: this.ptovta.local_accesorios,
        cupon: this.cupon,
        efectivo: this.giftcard ? 0 : efectivo,
        subtotal: this.monto_articulos
      })
      this.$store.state.loading_dct = false

      if (result.exito == 1) {
        this.empleado = result.data.empleado
        this.lista_desc = result.data.lista
        this.promociones = result.data.descuentos
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }

      // voy a trabajar sobre una copia
      let articulos_copy = await JSON.parse(JSON.stringify(this.articulos))

      // antes de aplicar los descuentos limpio el array de articulos
      for (let articulo of articulos_copy) {
        let art_pre = this.precios.find(art => art.codigo == articulo.codigo)
        if (art_pre) {
          const precio = art_pre.precio
          articulo.precio = precio
          articulo.precio_lista = precio
          articulo.importe = precio * articulo.cantidad
        } else {
          // si no lo encontro es pq es una gf
          articulo.precio = articulo.precio_lista
          articulo.importe = articulo.precio_lista
        }
        articulo.descuento = 0
      }

      // aplico los descunetos
      for (const promo of this.promociones) {
        // busco el articulo en el array
        let articulo = articulos_copy.find(art => art.codigo == promo.articulo)
        if (articulo) {
          const precio_mayorista = promo.precio_mayorista
          // verifico si es empleado si corresponde precio mayorista
          if (this.empleado == 1 && precio_mayorista > 0) {
            articulo.precio = precio_mayorista
            articulo.precio_lista = precio_mayorista
            articulo.importe = precio_mayorista * promo.cantidad
          }
          // aplico los descuentos
          let porcentaje = 0
          for (const desc of promo.detalle) {
            porcentaje += parseFloat(desc.porcentaje)
          }
          let descuento = porcentaje / 100
          descuento = parseFloat(descuento)
          
          articulo.descuento = porcentaje
          articulo.precio -= articulo.precio * descuento
          articulo.importe -= articulo.importe * descuento
        }
      }

      // si es efectivo redondeo los montos
      if (this.efectivo) {
        for (let articulo of articulos_copy) {
          articulo.precio = Math.round(articulo.precio / this.redondeo) * this.redondeo
          articulo.importe = articulo.precio * articulo.cantidad
        }
      }

      // si el documento no es indefinido significa q entró por el modulo de clientes
      if (documento != undefined) {
        // en este caso solo rearmo el array de financiaciones especiales si el subtotal cambia
        const monto_copia = parseFloat(articulos_copy.reduce((sum, art) => sum + art.importe, 0))
        if (roundNumberTF(this.monto_articulos) != roundNumberTF(monto_copia)) {
          this.articulos = articulos_copy
          this.armarArrayFinanciacionEspecial()
        }
      } else {
        this.articulos = articulos_copy
        if (limpiar_fp == 1) this.armarArrayFinanciacionEspecial()
      }

      this.validarGF()
    },
    getPtovta () {
      let locales = this.locales_user.filter(loc => loc.sucursal == this.sucursal && loc.canal == 1)
      if (locales.length == 1) {
        this.ptovta = locales[0]
      } else {
        this.ptovta = {}
      }
    },
    /********
      VARIOS
     ********/
    setNuevoClinte (valor) {
      this.cliente_nuevo = valor
    },
    async validarCliente () {
      return !this.cliente.num_doc && !this.cliente.cuit
    },
    async validarCupon () {
      if (!this.cupon) {
        this.getDescuentos(1)
        return
      }

      this.$store.state.loading = true
      let result = await this.$store.dispatch('ventas/validar_cupon', this.cupon)
      this.$store.state.loading = false

      if (result.exito != 1) {
        this.cupon = null
        this.getDescuentos(1)
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
        return
      }

      if (result.valido != 1) {
        this.cupon = null
        this.$swal.fire({
          icon: 'info',
          title: 'Cupón invalido',
          text: 'El cupón ingresado ya fue utilizado, no es un cupón valido, o no esta vigente'
        })
      }
      this.getDescuentos(1)

    },
    async buscarVendedor () {
      if (this.vendedor == null || this.vendedor == '') {
        this.vendedor_acc = null
        this.vendedor_nombre = null
        return
      }
      let vendedor = this.vendedores.find(ven => ven.codigo == this.vendedor)
      if (vendedor) {
        this.vendedor_acc = vendedor.codigo_acc
        this.vendedor_nombre = vendedor.nombre
      } else {
        this.vendedor = null
        this.vendedor_acc = null
        this.vendedor_nombre = null
        this.modal_vendedores = true
      }
    },
    seleccionarVendedor (ven) {
      this.vendedor = ven.codigo
      this.vendedor_acc = ven.codigo_acc
      this.vendedor_nombre = ven.nombre
    },
    limpiar () {
      this.debito = false
      this.efectivo = false
      this.giftcard = false
      this.combo = false
      this.cupon = null
      this.terminal = null
      this.vendedor = null
      this.vendedor_acc = null
      this.vendedor_nombre = null
      this.lista_desc = null
      this.empleado = 0
      this.promociones = []
      this.precios = []
      this.terminales = []
      this.vendedores = []
      this.articulos = []
      this.expanded = []
      this.financiaciones_especiales = []
      this.financiaciones_especiales_articulos = []
      this.financiaciones_disponibles = []
      this.formas_pago = []
      this.formas_pago_group = []
      this.init_fp.bancos = []
      this.cliente = {}
      this.limpiarFP()
      this.limpiarGF()
    },
    itemRowBackground (item) {
      return item.financiaciones_especiales.length > 0 ? 'background-color: rowsel' : ''
    },
    enterKey (event) {
      if (event.key == 'Enter') document.activeElement.blur()
    }
  }
}
</script>

<style>
.pulse-light {
  animation: pulse-light-animation 2s infinite;
  width: 10px;
  height: 10px;
  margin-left: 6px;
  margin-right: 12px;
  border-radius: 50%;
}

@keyframes pulse-light-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}

.pulse-dark {
  animation: pulse-dark-animation 2s infinite;
  width: 10px;
  height: 10px;
  margin-left: 6px;
  margin-right: 12px;
  border-radius: 50%;
}

@keyframes pulse-dark-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}
</style>